<template>
  <div class="out_of_stock">
    
<FormKitLazyProvider>
<FormKit
      type="form"
      :incomplete-message="$t('Sorry, not all fields are filled out correctly.')"
      form-class="out_of_stock__form"
      :actions="false"
      @submit="submit"
    >
      <FormKit
        v-model="email"
        type="email"
        name="email"
        :placeholder="$t('Email address')"
        validation="required|email"
        validation-visibility="blur"
        :validation-messages="{
          required: 'This is a required field.',
          email: 'Please enter a valid email address.',
        }"
      />

      <button
        type="submit"
        class="out_of_stock__button button button--cta"
        :disabled="outOfStockNotificationLoading || outOfStockNotificationError"
      >
        <span>{{ $t('Notify me') }}</span>
      </button>
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>

<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import type { MagentoSimpleProduct } from '~/types/magento'
import subscribeStockNotificationMutation from '~/graphql/pdp/subscribeStockNotification.gql'

export interface Props {
  products: MagentoSimpleProduct[]
}

const props = defineProps<Props>()
const email = ref<string>()
const { show: showNotification } = useNotification()
const { t } = useI18n({ useScope: 'global' })

const {
  mutate: outOfStockNotificationMutation,
  loading: outOfStockNotificationLoading,
  called: outOfStockNotificationCalled,
  error: outOfStockNotificationError,
} = useMutation(
  subscribeStockNotificationMutation,
  { clientId: 'customer' },
)

const submit = async () => {
  try {
    for (const i in props.products) {
      await outOfStockNotificationMutation({
        email: email.value,
        productId: props.products[i].id,
      })
    }
  }
  catch (outOfStockNotificationException: any) {
    console.error('outOfStockNotificationException', outOfStockNotificationException)
    showNotification({ type: 'error', message: t('Out of stock subscription disabled') })
    return
  }

  if (outOfStockNotificationCalled.value) {
    showNotification({ type: 'success', message: t('Your subscription has been saved') })
  }
  else if (outOfStockNotificationError.value) {
    showNotification({ type: 'error', message: outOfStockNotificationError.value })
  }
}
</script>

<style src="~layers/app/components/Product/OutOfStockNotification.css" />
