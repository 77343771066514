<template>
  <div class="product-reviews">
    <ReviewSummary
      class="product-reviews__summary"
      :stats="product.reviewStats"
    />

    <ReviewStarDistribution
      v-if="product.reviewStats.count"
      class="product-reviews_-star-distribution"
      :stats="product.reviewStats"
    />

    <button
      class="product-reviews__add button button--center"
      @click="modalReviewCreate.open"
    >
      <span>{{ $t('Add review') }}</span>
    </button>

    <ul class="product-reviews__list">
      <li
        v-for="(review, index) in reviews.items"
        :key="index"
        class="product-reviews__item"
      >
        <ReviewTile :review="review" />
      </li>
    </ul>

    <PaginatorToolbar
      v-if="reviews.page_info.total_pages > 1"
      class="plp__paginator plp__paginator--bottom"
      :current-page="reviews.page_info.current_page"
      :total-pages="reviews.page_info.total_pages"
      @update-page="loadMore"
    />
  </div>
</template>

<script setup lang="ts">
import type { MagentoProduct } from '~/types/magento'
import GetReviewsQuery from '~/graphql/pdp/getReviews.gql'

export interface Props {
  product: MagentoProduct
}
const props = defineProps<Props>()
const modalReviewCreate = useUiDialog('modal-review-create')
const reviews = ref(props.product.reviews)

const loadMore = (page: int) => {
  const { onResult } = useQuery(GetReviewsQuery, () => ({
    sku: props.product.sku,
    currentPage: page,
  }))

  onResult((queryResult) => {
    if (queryResult.data?.products?.items?.[0]?.reviews) {
      reviews.value = queryResult.data.products.items[0].reviews
    }
  })
}
</script>

<style src="~layers/app/components/Product/Reviews/Reviews.css" />
