<template>
  <div class="star-distribution">
    <table class="star-distribution__table table table--justified table--valign-middle">
      <thead>
        <tr>
          <th colspan="2">
            {{ $t('Stars') }}
          </th>
          <th>{{ $t('Quantity visual') }}</th>
          <th>{{ $t('Quantity') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="rating in ratings"
          :key="`stars-${rating.stars}`"
        >
          <th class="col-stars">
            {{ rating.stars }}
          </th>
          <td class="col-icon">
            <IconStar class="icon icon--star" />
          </td>
          <td class="col-progress">
            <meter
              class="star-distribution__progress"
              :value="Math.round((rating.count * 100) / stats.count)"
              max="100"
            />
          </td>
          <td class="col-count">
            {{ rating.count }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
export type Props = {
  stats: {
    score: number
    count: number
    rating: number
    stars: never[]
  }
}
const props = defineProps<Props>()

const ratings = computed(() => {
  return [5, 4, 3, 2, 1].map((item: number) => {
    return props.stats.stars.find(({ stars }) => stars === item) || { stars: item, count: 0 }
  })
})
</script>

<style src="~/components/Review/StarDistribution.css" />
