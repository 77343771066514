<template>
  <div class="review-stars">
    <template
      v-for="i in 5"
      :key="`score-start-${i}`"
    >
      <IconStar
        v-if="i <= stars"
        class="icon icon--star icon--star-on"
      />
      <IconStar
        v-else
        class="icon icon--star icon--star-off"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
export type Props = {
  rating: number
}
const props = defineProps<Props>()

const stars = computed(() =>
  props.rating > 0 ? Math.ceil(props.rating) : 0,
)
</script>

<style src="~/components/Review/Stars.css" />
