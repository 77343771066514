<template>
  <div
    v-if="product.brand"
    class="pdp__brand"
  >
    <img
      v-if="image"
      :src="image"
      :alt="product.brand.label"
    >
    <span v-else>{{ product.brand.label }}</span>
  </div>
</template>

<script setup lang="ts">
import type { EsProduct } from '~/types/elastic'

export type Props = {
  product: EsProduct
}

const { public: config } = useRuntimeConfig()
const props = defineProps<Props>()

const image = computed<string | null>(() =>
  props.product.brand?.swatch ? config.xcdnUrl + props.product.brand?.swatch : null,
)
</script>
