<template>
  <div class="media-gallery-thumbs">
    <button
      v-show="scrollableThumbs && !arrivedState.top"
      type="button"
      class="media-gallery-thumbs__prev"
      @click="prevThumb"
    >
      <IconArrow class="icon--arrow-up" />
    </button>
    <div
      ref="thumbs"
      class="media-gallery-thumbs__container"
    >
      <button
        v-for="item in galleryItems"
        :key="item.src"
        type="button"
        :data-thumb="item.index"
        class="media-gallery-thumbs__thumb"
        :class="{
          'media-gallery-thumbs__thumb--active': activeItem?.src === item.src,
        }"
        @mouseover="setItem(item.index)"
      >
        <UiVideo
          v-if="item.type === 'video'"
          :src="item.src"
          width="240"
          height="290"
          class="media-gallery-thumbs__video"
          :controls="false"
        />

        <img
          v-else-if="item.type === 'image'"
          :src="getImageFromProvider(item, { width: 240, height: 290 })"
          :alt="item.alt"
          width="240"
          height="290"
          loading="lazy"
          class="media-gallery-thumbs__image"
        >
      </button>
    </div>
    <button
      v-show="scrollableThumbs && !arrivedState.bottom"
      type="button"
      class="media-gallery-thumbs__next"
      @click="nextThumb"
    >
      <IconArrow class="icon--arrow-down" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useScroll } from '@vueuse/core'

const {
  galleryItems,
  activeItem,
  setItem,
  // gallery,
  thumbs,
  scrollableThumbs,
  getImageFromProvider,
} = useUiMediaGallery()

const {
  // x,
  y,
  // isScrolling,
  arrivedState,
  // directions
} = useScroll(thumbs, { behavior: 'smooth' })

function prevThumb() {
  y.value = -(thumbs.value?.offsetHeight || 0)
}

function nextThumb() {
  y.value = thumbs.value?.offsetHeight || 0
}

onMounted(() => {
  scrollableThumbs.value = (thumbs.value?.scrollHeight || 0) > (thumbs.value?.offsetHeight || 0)
})
</script>

<style src="~layers/app/components/Ui/MediaGallery/Thumbs.css" />
