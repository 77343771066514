<template>
  <a
    href="#"
    class="score"
    @click.prevent="stats.count ? modalReviews.open() : modalReviewCreate.open()"
  >
    <ReviewStars
      class="score__stars"
      :rating="stats?.rating || 0"
    />

    <div
      v-if="showRating"
      class="score__text"
    >
      {{ stats?.rating || 0 }} / 5.0
    </div>

    <span
      v-else
      class="score__text"
    >
      {{ $t('Review count', { count: stats.count }, stats.count) }}
    </span>
  </a>
</template>

<script setup lang="ts">
export type Props = {
  stats: {
    score: number
    count: number
    rating: number
  }
  showRating?: boolean
}
defineProps<Props>()

const modalReviews = useUiDialog('modal-reviews')
const modalReviewCreate = useUiDialog('modal-review-create')
</script>

<style src="~/components/Product/Reviews/Score.css" />
