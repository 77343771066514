<template>
  <LazyUiDialog
    class="media-gallery-fullscreen"
    name="media-gallery"
    dialog-type="fullscreen"
  >
    <div class="media-gallery-fullscreen__container">
      <UiMediaGalleryThumbs class="media-gallery-fullscreen__thumbs" />

      <UiMediaGalleryFullscreenMain class="media-gallery-fullscreen__main" />

      <div class="media-gallery-fullscreen__controls">
        <UiMediaGalleryXofY class="media-gallery-fullscreen__x-of-y">
          <template #divider>
            van
          </template>
        </UiMediaGalleryXofY>

        <UiMediaGalleryPrevNext class="media-gallery-fullscreen__navigation" />

        <button
          class="media-gallery-fullscreen__close"
          @click="closeFullscreen"
        >
          <IconClose class="icon--close" />
        </button>
      </div>
    </div>
  </LazyUiDialog>
</template>

<script lang="ts" setup>
const { close: closeFullscreen } = useUiDialog('media-gallery')
</script>

<style src="~layers/app/components/Ui/MediaGallery/Fullscreen.css" />
