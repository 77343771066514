<template>
  <div class="review-tile">
    <div class="review-tile__header">
      <ReviewStars
        class="review-tile__stars"
        :rating="rating"
      />

      <div class="review-tile__score">
        {{ rating }} / 5.0
      </div>
    </div>

    <div class="review-tile__heading heading heading--6">
      {{ review.summary }}
    </div>

    <ul class="review-tile__list">
      <li
        v-for="(pro, index) in pros"
        :key="`pros-${index}`"
        class="review-tile__item"
      >
        <IconPlus class="icon icon--plus" />
        <span>{{ pro }}</span>
      </li>

      <li
        v-for="(con, index) in cons"
        :key="`cons-${index}`"
        class="review-tile__item"
      >
        <IconMin class="icon icon--min" />
        <span>{{ con }}</span>
      </li>
    </ul>

    <p class="review-tile__description">
      {{ review.text }}
    </p>

    <div class="review-tile__footer">
      <span class="review-tile__author">{{ review.nickname }}</span>
      |
      <time :datetime="review.created_at">
        <!-- woensdag, 10 januari 2018 -->
        {{ $d(review.created_at, 'long') }}
      </time>
    </div>

    <div
      v-if="review.isTranslated"
      class="review-tile__disclaimer"
    >
      <p>{{ $t('Review translated') }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
export type Props = {
  review: any
}
const props = defineProps<Props>()

const pros = computed(() =>
  props.review.pros.filter((item: never) => item),
)

const cons = computed(() =>
  props.review.cons.filter((item: never) => item),
)

const rating = computed(() =>
  props.review.average_rating > 0 ? Math.round((props.review.average_rating / 100) * 50) / 10 : 0,
)
</script>

<style src="~layers/app/components/Review/Tile.css" />
