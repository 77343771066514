<template>
  <div class="upsell-product">
    <div class="upsell-product__heading heading heading--5">
      {{ $t('A better alternative') }}
    </div>

    <AppLink
      :to="upsellProduct.url"
      class="upsell-product__link"
    >
      <img
        v-if="upsellProduct?.image"
        class="upsell-product__image"
        :src="upsellProduct.image"
      >

      <div class="upsell-product__content">
        <div class="upsell-product__name">
          {{ upsellProduct.name }}
        </div>

        <ProductPrice
          class="upsell-product__price"
          :prices="{
            regularPrice: upsellProduct.regularPrice,
            finalPrice: upsellProduct.finalPrice,
          }"
        />

        <ul class="upsell-product__list">
          <li
            v-for="(upsell, index) in upsells"
            :key="index"
            class="upsell-product__item"
          >
            <IconCssArrow2 class="icon icon--arrow icon--arrow-right" />
            <span v-html="upsell" />
          </li>
        </ul>
      </div>
    </AppLink>
  </div>
</template>

<script setup lang="ts">
export type Props = {
  product: object
}
const props = defineProps<Props>()

const upsellProduct = computed(() => props.product.upsellProduct.product)
const upsells = computed(() => props.product.upsellProduct.upsells || [])
</script>

<style src="~layers/app/components/Product/UpsellProduct.css" />
