<template>
  <div
    v-if="activeItem"
    class="media-gallery__navigation"
  >
    <button
      v-if="activeItem.index > 1"
      type="button"
      class="media-gallery__prev"
      @click="setItem(activeItem.index - 1, 'smooth')"
    >
      <IconArrow class="icon icon--arrow icon--arrow-left" />
      <span>{{ $t('Previous') }}</span>
    </button>

    <button
      v-if="activeItem.index < galleryItems.length"
      type="button"
      class="media-gallery__next"
      @click="setItem(activeItem.index + 1, 'smooth')"
    >
      <IconArrow class="icon icon--arrow icon--arrow-right" />
      <span>{{ $t('Next') }}</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
const {
  galleryItems,
  activeItem,
  setItem,
} = useUiMediaGallery()
</script>

<style src="~layers/app/components/Ui/MediaGallery/PrevNext.css" />
