<template>
  <div>
    {{ activeItem?.index }}
    <slot name="divider">
      /
    </slot>
    {{ galleryItems.length }}
  </div>
</template>

<script lang="ts" setup>
const { galleryItems, activeItem } = useUiMediaGallery()
</script>

<style src="~layers/app/components/Ui/MediaGallery/XofY.css" />
