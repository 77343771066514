<template>
  <div class="pdp pdp__container container">
    <AppBreadcrumbs />
    <!-- <AppHistoryBack class="pdp__back--link" /> -->

    <div class="pdp__layout">
      <div class="pdp__product">
        <div class="pdp__header">
          <h1 class="pdp__heading heading heading--5">
            {{ esProduct?.name }}
          </h1>

          <ProductReviewsScore
            class="pdp__score"
            :stats="m2Product.reviewStats"
          />
        </div>

        <div
          ref="addToCart"
          class="pdp__info"
        >
          <ProductAddToCart
            class="pdp__addtocart"
            :product="m2Product"
          >
            <template #below-promise>
              <ProductRelatedColorProducts
                class="pdp__colors"
                :product="esProduct"
              />
            </template>
          </ProductAddToCart>

          <LazyProductPromo
            v-for="promo in promos"
            :key="promo.documentId"
            class="pdp__promo"
            :promo="promo"
          />

          <LazyAppUsps
            v-if="extendedUsps?.length"
            class="pdp__usps"
            :usps="extendedUsps"
          />
        </div>
      </div>

      <div class="pdp__media">
        <ProductLabelBrand :product="esProduct" />

        <div class="pdp__labels">
          <ProductLabelSale
            v-if="esProduct.isSale && esProduct.discount >= 40"
            :prices="productPrices"
          />
          <ProductLabelMarketing :product="esProduct" />
        </div>

        <UiMediaGallery
          v-if="productMediaGallery"
          :media="productMediaGallery"
        />

        <div
          id="videoly-videobox-placeholder"
          class="pdp__videoly"
        />
      </div>

      <div class="pdp__extra-data">
        <ProductUpsellProduct
          v-if="esProduct.upsellProduct"
          class="pdp__upsell-product"
          :product="esProduct"
        />

        <LazyUiCollapsable
          v-if="m2Product?.attributeGroups?.length"
          class="pdp__specifications specifications"
          :label="$t('Specifications')"
          open
        >
          <LazyUiCollapsable
            v-for="(attributeGroup, index) in m2Product?.attributeGroups"
            :key="attributeGroup.code"
            :label="$t(attributeGroup.name)"
            :open="!index"
            class="specifications__group"
          >
            <table class="specifications__table table table--spacing table--grid-rows">
              <tbody>
                <tr
                  v-for="spec in filteredAttributes(attributeGroup.attributes)"
                  :key="spec.code"
                >
                  <th>{{ spec.label }}</th>
                  <td>{{ spec.value }}</td>
                </tr>
              </tbody>
            </table>
          </LazyUiCollapsable>
        </LazyUiCollapsable>

        <LazyUiCollapsable
          v-if="m2Product?.description?.html?.trim()"
          class="pdp__description"
          :label="$t('Product description')"
        >
          <!-- <UiRichtext :html="m2Product.description.html" /> -->
          <div v-html="m2Product.description.html" />
        </LazyUiCollapsable>

        <LazyCatalogQuickLinks
          v-if="categoryBrandLinks.length"
          :title="$t('Related products')"
          :links="categoryBrandLinks"
          class="pdp__quick-links"
        />

        <div
          id="product-reviews"
          class="pdp__reviews-cta reviews-cta"
        >
          <template v-if="m2Product.reviewStats.count">
            <div class="reviews-cta__header">
              <div class="reviews-cta__reviews-score">
                <IconStar class="icon icon--star" />
                {{ m2Product.reviewStats.rating }} / 5.0
              </div>

              <div class="reviews-cta__reviews-count">
                {{ $t('Based on reviews', { count: m2Product.reviewStats.count }, m2Product.reviewStats.count) }}
              </div>
            </div>

            <button
              class="reviews-cta__button button button--cta button--center"
              type="button"
              @click="modalReviews.open"
            >
              <span>{{ $t('Show all reviews') }}</span>
              <IconCssArrow2 class="icon icon--arrow icon--arrow-right" />
            </button>
          </template>

          <template v-else>
            <div class="reviews-cta__header">
              <div class="reviews-cta__reviews-score">
                {{ $t('No reviews') }}
              </div>
            </div>

            <button
              class="reviews-cta__button button button--cta button--center"
              type="button"
              @click="modalReviewCreate.open"
            >
              <span>{{ $t('Add review') }}</span>
              <IconCssArrow2 class="icon icon--arrow icon--arrow-right" />
            </button>
          </template>

          <LazyUiDialog
            v-if="m2Product.reviewStats.count"
            name="modal-reviews"
            class="modal-reviews"
            dialog-type="responsive"
          >
            <template #dialogHeader>
              {{ $t('Reviews') }}
            </template>

            <ProductReviews :product="m2Product" />
          </LazyUiDialog>

          <LazyUiDialog
            class="modal-review-create"
            name="modal-review-create"
            dialog-type="responsive"
          >
            <template #dialogHeader>
              <span
                v-if="reviewCreateSuccess"
                v-text="$t('Thank you for adding review for', { product: esProduct.name })"
              />
              <span
                v-else
                v-text="$t('Create your review for', { product: esProduct.name })"
              />
            </template>
            <p v-if="reviewCreateSuccess">
              {{ $t('It takes up to 24 hours') }}
            </p>

            <ClientOnly v-else>
              <LazyFormReviewCreate

                :product="esProduct"
                @success="(val) => reviewCreateSuccess = val"
              />
            </ClientOnly>
          </LazyUiDialog>
        </div>

        <ThirdpartySqueezelyProductSlider id="spotler-activate-pdp-slider-1" />

        <ThirdpartySqueezelyProductSlider id="spotler-activate-pdp-slider-2" />

        <ThirdpartySqueezelyProductSlider id="spotler-activate-pdp-slider-3" />

        <!-- <ProductSlider
          v-if="upsellProducts?.length"
          :products="upsellProducts"
          class="pdp__upsell upsell"
          :settings="{ wrapAround: false, snapAlign: 'start' }"
          :breakpoints="{}"
        >
          <div
            class="recently-viewed__heading heading heading--6"
            v-text="$t('Complete your look')"
          />
        </ProductSlider> -->

        <!-- <ProductSlider v-if="crosssellProducts?.length" :products="crosssellProducts" class="pdp__slider pdp__crosssell" :settings="{ wrapAround: false }">
          <h6 v-text="$t('Crosssell Products')" />
        </ProductSlider> -->

        <!-- <ProductSlider v-if="relatedProducts?.length" :products="relatedProducts" class="pdp__slider pdp__related" :settings="{ wrapAround: false }">
          <h6 v-text="$t('Related Products')" />
        </ProductSlider> -->

        <!-- <ProductSlider v-if="interestingProducts?.length" :products="interestingProducts" class="pdp__slider pdp__interesting" :settings="{ wrapAround: false }">
          <h6 v-text="$t('Interesting Products')" />
        </ProductSlider> -->

        <!-- <ProductSlider v-if="recentlyViewed?.length" :products="recentlyViewed" class="pdp__slider pdp__recently-viewed" :settings="{ wrapAround: false }">
          <h6 v-text="$t('Latest favourites')" />
        </ProductSlider> -->

        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
        <!--  -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import GetPromosQuery from '~layers/app/graphql/pdp/getPromos.gql'

const route = useRoute()
const { t: translate } = useI18n({ useScope: 'global' })
const meta = inject('meta', ref())
const { t } = useI18n({ useScope: 'global' })

const { public: config, app } = useRuntimeConfig()

const {
  m2Product,
  esProduct,
  attributes,
  parentCategories,
  categories,
  // crosssellProducts,
  // upsellProducts,
  // relatedProducts,
  // interestingProducts
  pdpUsps,
} = await usePDP(meta)

const { data: promoData } = await useAsyncQuery<never>(GetPromosQuery, {
  sku: esProduct.value.sku,
  brand: esProduct.value.brand.label,
  parentKey: String(esProduct.value.attr_kat_parent_ext_key),
  locale: config.strapiLocale,
  status: 'PUBLISHED',
})

const promos = computed<never[]>(() => {
  return (promoData?.value?.promos?.nodes || []).filter((item: any) => {
    const activeFrom = new Date(item.ActiveFrom).getTime()
    const activeUntil = new Date(item.ActiveUntil).getTime()
    const now = new Date()
    return activeFrom < now && activeUntil > now
  })
})

provide('esProduct', esProduct)

const modalReviews = useUiDialog('modal-reviews')
const modalReviewCreate = useUiDialog('modal-review-create')

const reviewCreateSuccess = ref(false)

// const addToCart = ref<HTMLElement | null>(null)
// const addToCartIsVisible = useElementVisibility(addToCart)

const productMediaGallery = computed(() => {
  return (m2Product?.value?.media_gallery || []).toSorted((a, b) => {
    return a.position - b.position
  }).map((media: any) => {
    return {
      alt: media.label,
      src: media.url,
    }
  })
})

const extendedUsps = computed(() => {
  return [
    ...pdpUsps.value,
    {
      Label: translate('Warranty period', attributes.value),
      Description: null,
      Link: null,
      Icon: 'check',
    },
  ]
})

const productPrices = computed(() => {
  return {
    regularPrice: m2Product?.value?.price_range?.minimum_price?.regular_price?.value,
    finalPrice: m2Product?.value?.price_range?.minimum_price?.final_price?.value,
  }
})

const categoryBrandLinks = computed(() => {
  const result: any[] = [
    {
      Name: esProduct.value.brand.label,
      Link: stringToSlug(esProduct.value.brand.label.replace('&', 'and')),
    },
  ]
  categories.value.forEach((category: any) => {
    if (category?.name && category?.url) {
      result.push({
        Name: category.name,
        Link: category.url,
      })
      if (esProduct?.value?.brand?.label && category?.level < 3) {
        result.push({
          Name: esProduct.value.brand.label + ' ' + category.name,
          Link: category.url + '/' + stringToSlug(esProduct.value.brand.label.replace('&', 'and')),
        })
      }
    }
  })
  return result
})

const { setBreadcrumbs } = useBreadcrumbs()
setBreadcrumbs({
  label: esProduct?.value?.name,
  parents: [...parentCategories.value].sort((a, b) => a.level - b.level),
})

const filteredAttributes = (attributes: any[]) => {
  return attributes.filter((spec: any) => {
    return spec.value && spec.value.trim() !== ''
  })
}

const { pageView, viewItem } = useDataLayer()
pageView('detail', esProduct?.value?.name)
viewItem(
  esProduct?.value,
  { skus: m2Product.value?.variants?.map((variant: any) => variant.product.sku) || [esProduct.value?.sku] },
)

onMounted(() => {
  if (route.query.review === null) {
    nextTick(() => {
      modalReviewCreate.open()
    })
  }
})

const metaTitle = computed(
  () => esProduct?.value?.name + ' ' + esProduct?.value?.sku,
)

const metaDescription = computed(
  () => t(`pdp.metaDescription`, { name: esProduct?.value?.name, sku: esProduct?.value?.sku }),
)

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: 'index, follow' },
  ],
})

const { canonical } = useSeo()
canonical(computed(() => route.path))

const productSnippet = () => {
  const { locale, locales } = useI18n({ useScope: 'global' })
  const currency = locales.value.find(lcl => lcl.code === locale.value)?.numberFormats.currency.currency || 'EUR'
  const reviewStats = m2Product.value?.reviewStats?.count ? m2Product.value?.reviewStats : null
  // const review = m2Product.value?.reviews?.items[0]

  // const reviews = review
  //   ? {
  //       '@type': 'Review',
  //       'reviewRating': {
  //         '@type': 'Rating',
  //         'ratingValue': review?.average_rating,
  //         'bestRating': 100,
  //       },
  //       'author': {
  //         '@type': 'Person',
  //         'name': review?.nickname,
  //       },
  //     }
  //   : undefined

  const aggregateRating = reviewStats
    ? {
        '@type': 'AggregateRating',
        'ratingValue': reviewStats?.rating,
        'reviewCount': reviewStats?.count,
      }
    : undefined

  useHead({
    script: [
      {
        hid: 'Product',
        type: 'application/ld+json',
        innerHTML: {
          '@context': 'https://schema.org',
          '@type': 'Product',
          'name': `${esProduct.value?.name} ${esProduct.value?.descriptiveName}`,
          'image': `${config.xcdnUrl}OQ75_RM830,1066,255,255,255/-/catalog/product${esProduct.value?.image}`,
          // 'description': this.metaDescription,
          'sku': esProduct.value?.sku,
          'brand': {
            '@type': 'Brand',
            'name': esProduct.value?.brand?.label,
          },
          'offers': {
            '@type': 'Offer',
            'url': `${config.baseDomain}${app.baseURL}${esProduct.value?.url}`.replace(/\/+/g, '/').replace(':/', '://'), // Safari compatible version
            'priceCurrency': currency,
            'price': esProduct.value?.finalPrice || 0,
            'itemCondition': 'https://schema.org/NewCondition',
            'availability': `https://schema.org/${m2Product.value?.stock_status === 'OUT_OF_STOCK' ? 'OutOfStock' : 'InStock'}`,
          },
          // ...{ review: reviews },
          ...{ aggregateRating: aggregateRating },
        },
      },
    ],
  })
}

productSnippet()

// const { recentlyViewed } = useRecentlyViewed(esProduct?.value?.sku)
</script>

<style src="~layers/app/pages/~pdp.css" />
