<template>
  <div
    v-if="galleryItems.length"
    class="media-gallery"
  >
    <div class="media-gallery__container">
      <UiMediaGalleryMain class="media-gallery__main" />
      <UiMediaGalleryThumbs class="media-gallery__thumbs" />
      <!-- <UiMediaGalleryDots class="media-gallery__dots" /> -->
      <UiMediaGalleryXofY class="media-gallery__x-of-y" />
      <slot />
    </div>

    <UiMediaGalleryFullscreen />
  </div>
</template>

<script lang="ts" setup>
import type { Item } from '~layers/app/composables/useUiMediaGallery'

const props = defineProps({
  media: {
    type: Array as PropType<Item[]>,
    default: () => [],
  },
})

const { galleryItems } = useUiMediaGallery({
  media: props.media,
  index: 1,
})

const { isOpen: isFullscreen } = useUiDialog('media-gallery')

onUnmounted(() => {
  isFullscreen.value = false
})
</script>

<style src="~layers/app/components/Ui/MediaGallery/index.css" />
