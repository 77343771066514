<template>
  <div
    v-if="relatedColorProducts && relatedColorProducts.length"
    class="variations"
  >
    <div class="variations__heading heading heading--6">
      <!-- <span>{{ $t('Related color count', { count: relatedColorProducts.length }, relatedColorProducts.length) }}</span> -->
      <span>{{ $t('Choose your color') }}</span>
    </div>

    <div class="variations__content">
      <ul class="variations__list">
        <li class="variations__item">
          <span class="variations__link current">
            <span class="variations__visual">
              <img
                :src="productImageUrl(product?.image, '?f=rs:fit:65:65', product?.name)"
                width="65"
                height="65"
                :alt="product?.name"
                loading="lazy"
                class="variations__image"
              >
            </span>
          </span>
        </li>
        <li
          v-for="item in relatedColorProducts.slice(0, limit)"
          :key="item.id"
          class="variations__item"
        >
          <AppLink
            :to="link(item)"
            class="variations__link"
          >
            <span class="variations__visual">
              <img
                :src="productImageUrl(item.image, '?f=rs:fit:65:65', item.name)"
                width="65"
                height="65"
                :alt="item.name"
                loading="lazy"
                class="variations__image"
              >
            </span>
          </AppLink>
        </li>
      </ul>

      <button
        v-if="relatedColorProducts.length > limit"
        type="button"
        class="variations__more"
        @click="modalColors.open"
      >
        +{{ relatedColorProducts.length - 5 }}
      </button>
    </div>

    <LazyUiDialog
      class="variants__modal"
      name="modal-colors"
      dialog-type="responsive"
    >
      <template #dialogHeader>
        {{ $t('Colors') }}
      </template>

      <ul class="modal-colors__content">
        <li class="variations__item">
          <span class="variations__link current">
            <span class="variations__visual">
              <img
                :src="productImageUrl(product?.image, '?f=rs:fit:65:65', product?.name)"
                width="65"
                height="65"
                :alt="product?.name"
                loading="lazy"
                class="variations__image"
              >
            </span>

            <span class="variations__label">{{ product.supplierColor || product?.color.label }}</span>
          </span>
          <!-- <pre>{{ product }}</pre> -->
        </li>
        <li
          v-for="item in relatedColorProducts"
          :key="item.id"
          class="variations__item"
        >
          <AppLink
            :to="link(item)"
            class="variations__link"
            @click="modalColors.close"
          >
            <span class="variations__visual">
              <img
                :src="productImageUrl(item.image, '?f=rs:fit:65:65', item.name)"
                width="65"
                height="65"
                :alt="item.name"
                loading="lazy"
                class="variations__image"
              >
            </span>

            <span class="variations__label">
              {{ item.supplierColor || item.color.label }}
            </span>
            <!-- <pre>{{ item }}</pre> -->
          </AppLink>
        </li>
      </ul>
    </LazyUiDialog>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  product
}>()

const limit = 5
const modalColors = useUiDialog('modal-colors')

const relatedColorProducts = computed(() => {
  return props.product?.relatedColorProducts
})

const productImageUrl = (url: string, params: string) => {
  return `${url}${params}`
}
const link = (product: any) => {
  return `${product.url}`
}
</script>

<style src="~layers/app/components/Product/RelatedColorProducts.css" />
