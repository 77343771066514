<template>
  <div
    class="media-gallery-fullscreen-main"
    :class="{
      'media-gallery-fullscreen-main--zoom': isZoom,
    }"
  >
    <ul class="media-gallery-fullscreen-main__list">
      <li
        v-for="item in galleryItems"
        v-show="activeItem?.index === item.index"
        :key="item.src"
        class="media-gallery-fullscreen-main__item"
        :data-item="item.index"
        @click="toggleZoom"
      >
        <UiVideo
          v-if="item.type === 'video'"
          :src="item.src"
          width="720"
          height="870"
          :autoplay="activeItem?.index === item.index"
          :controls="true"
          playsinline
          loop
          muted
          class="media-gallery-fullscreen-main__video"
        />

        <div
          v-else-if="item.type === 'image'"
          class="media-gallery-fullscreen-main__contents"
        >
          <img
            :src="getImageFromProvider(item)"
            :alt="item.alt"
            width="720"
            height="870"
            loading="lazy"
            class="media-gallery-fullscreen-main__image"
          >

          <!-- <PinchScrollZoom
            class="media-gallery-fullscreen-main__zoom"
            :width="720"
            :height="870"
            :scale="1"
            :min-scale="1"
          >
            <img
              :src="getImageFromProvider(item)"
              :alt="item.alt"
              class="media-gallery-fullscreen-main__zoom-image"
              width="720"
              height="870"
              loading="eager"
            >
          </PinchScrollZoom> -->
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
const { galleryItems, activeItem, getImageFromProvider } = useUiMediaGallery()

const isZoom = ref<boolean>(false)

function toggleZoom() {
  isZoom.value = !isZoom.value
}
</script>

<style src="~layers/app/components/Ui/MediaGallery/FullscreenMain.css" />
