<template>
  <div class="media-gallery-main">
    <!-- <pre>activeItem {{ activeItem }}</pre> -->
    <!-- <pre>galleryItems {{ galleryItems }}</pre> -->

    <ul
      ref="lclGallery"
      v-scroll="onScroll"
      class="media-gallery-main__list"
    >
      <li
        v-for="item in galleryItems"
        :key="item.src"
        class="media-gallery-main__item"
        :data-item="item.index"
        @click="openFullscreen"
      >
        <UiVideo
          v-if="!isFullscreen && item.type === 'video'"
          :src="item.src"
          width="720"
          height="870"
          :autoplay="activeItem?.index === item.index"
          :controls="true"
          playsinline
          loop
          muted
          class="media-gallery-main__video"
        />

        <img
          v-else-if="item.type === 'image'"
          :src="getImageFromProvider(item, { width: 720 })"
          :alt="item.alt"
          width="720"
          height="870"
          loading="lazy"
          class="media-gallery-main__img"
        >
      </li>
    </ul>

    <UiMediaGalleryPrevNext class="media-gallery-main__navigation" />
  </div>
</template>

<script lang="ts" setup>
import type { UseScrollReturn } from '@vueuse/core'
import { vScroll } from '@vueuse/components'

const { open: openFullscreen, isOpen: isFullscreen } = useUiDialog('media-gallery')

const {
  galleryItems,
  activeItem,
  gallery,
  // toggleFullscreen,
  getImageFromProvider,
  // setItem,
  // isFullscreen
} = useUiMediaGallery()

const lclGallery = ref<null | HTMLElement>(null)

watchEffect(() => {
  gallery.value = lclGallery.value
})

let timer: ReturnType<typeof setTimeout> | null = null
function onScroll(state: UseScrollReturn) {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }

  timer = setTimeout(function () {
    onScrollEnd(state)
  }, 50)
}

function onScrollEnd(state: UseScrollReturn) {
  if (!gallery.value) {
    return
  }

  const galleryWidth = gallery.value.offsetWidth

  const scrollLeft = state.x.value
  const scrollRight = state.x.value + galleryWidth

  activeItem.value
    = galleryItems.value.find((item) => {
      const itemCenter = item.index * galleryWidth - galleryWidth / 2
      return itemCenter > scrollLeft && itemCenter < scrollRight
    }) || null
}
</script>

<style src="~layers/app/components/Ui/MediaGallery/Main.css" />
