<template>
  <div
    :class="isOos && !pendingStock && !pendingStock ? 'add-to-cart__stock' : 'add-to-cart'"
  >
    <ProductPrice
      v-if="productPrices"
      class="add-to-cart__price"
      :prices="productPrices"
      :show-currency="false"
      :show-vat-tag="true"
    />
    <div class="add-to-cart__stock stock">
      <div :class="isOos ? 'oos-stock__label' : 'stock__label'">
        <IconCheck
          v-show="!isOos"
          class="icon icon-check"
        />
        <span>{{ $t(isOos ? 'Out of stock' : 'In stock') }}</span>
      </div>

      <div
        v-show="!isOos"
        class="stock__promise"
      >
        <CatalogDeliveryPromise
          :item="deliveryPromises[0] || null"
          :country="deliveryPromiseCountry || storeConfig?.default_country"
        />
      </div>
    </div>

    <slot name="below-promise" />

    <ProductSelector
      :product="props.product"
      @selected="collect"
      @submit="add"
    >
      <template #oos="{ oos }">
        <ProductOutOfStockNotification
          v-if="oos.length"
          :products="oos"
        />
      </template>
    </ProductSelector>

    <template v-if="!isOos">
      <span
        v-if="hasError"
        ref="error"
        class="required"
      >{{ $t('Please choose a size') }}</span>

      <div class="add-to-cart__actions">
        <button
          type="button"
          :disabled="pending || pendingStock"
          class="add-to-cart__cta button button--shop-flow button--full"
          data-testid="add-to-cart"
          @click.prevent="add"
        >
          <span>{{ $t('Add to cart') }}</span>
          <span v-if="groupedPrice > 0">-</span>
          <i18n-n
            v-if="groupedPrice > 0"
            tag="span"
            :value="groupedPrice"
            format="currency"
            scope="global"
          >
            <template #currency="slotProps">
              <span class="price__currency">{{ slotProps.currency }}</span>
            </template>
            <template #integer="slotProps">
              <span class="price__integer">{{ slotProps.integer }}</span>
            </template>
            <template #decimal="slotProps">
              <span class="price__decimal">{{ slotProps.decimal }}</span>
            </template>
            <template #fraction="slotProps">
              <span class="price__fraction">{{ slotProps.fraction }}</span>
            </template>
          </i18n-n>
          <!-- <IconShoppingcart class="icon icon-cart" /> -->
        </button>

        <ProductToggleWishlist
          class="add-to-cart__wishlist-toggle button button--icon-only"
          :product="product"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type {
  MagentoCartItemInput,
  MagentoConfigurableProductCartItemInput,
  MagentoConfigurableProduct,
  MagentoGroupedProduct,
  MagentoProduct,
} from '~layers/app/types/magento'

const { storeConfig } = await useStore()

const {
  skus: deliveryPromiseSkus,
  country: deliveryPromiseCountry,
  load: loadDeliveryPromise,
  forSku: deliveryPromiseForSku,
} = useDeliveryPromise(true)

export interface Props {
  product: MagentoProduct | MagentoConfigurableProduct | MagentoGroupedProduct
}

const meta = inject('meta', ref())
const { addToCart, pending, shippingAddressOrBillingAddress } = useCart()
const hasError = ref(false)
const error = ref(null)
onClickOutside(error, () => {
  hasError.value = false
})

const selectedItems = ref<Record<string, MagentoCartItemInput | null>>({})
const props = defineProps<Props>()

const {
  loadStockData,
  pendingStock,
} = await usePDP(meta)

onMounted(() => {
  loadStockData()

  deliveryPromiseCountry.value = shippingAddressOrBillingAddress?.country?.code || storeConfig.value.default_country || ''
  deliveryPromiseSkus.value = skus.value.map((item: string) => {
    return item ? { sku: item, qty: 1 } : null
  }).filter(item => item)
  if (deliveryPromiseSkus.value.length) {
    loadDeliveryPromise()
  }
})

const variantPrices = computed<Record<string, number>>(() => {
  return props.product?.variants?.reduce((acc: Record<string, number>, variant: any) => {
    return {
      ...acc,
      [variant.product.sku]: {
        regularPrice: variant.product?.price_range?.minimum_price?.regular_price?.value,
        finalPrice: variant.product?.price_range?.minimum_price?.final_price?.value,
      },
    }
  }, {}) || {}
})

const skus = computed<string[]>(() => {
  if (props.product.__typename == 'ConfigurableProduct') {
    return props.product.variants.map((variant: never) => variant?.product?.sku)
  }
  return [props.product.sku]
})

const productPrices = computed(() => {
  const sku = selectedItems.value?.[props.product.sku]?.data?.sku
  if (sku && variantPrices.value?.[sku]) {
    return variantPrices.value?.[sku]
  }
  return {
    fromPrice: props.product?.product?.type === 'configurable' ? props.product?.price_range?.minimum_price?.final_price?.value : undefined,
    regularPrice: props.product?.price_range?.minimum_price?.regular_price?.value,
    finalPrice: props.product?.price_range?.minimum_price?.final_price?.value,
  }
})

const groupedPrice = computed(() => {
  return Object.values(selectedItems.value).reduce((acc: number, item: any) => {
    if (props.product?.__typename === 'GroupedProduct') {
      acc += variantPrices.value?.[item?.data?.sku] || 0
    }
    return acc
  }, 0)
})

const collect = (item: Record<string, MagentoCartItemInput | MagentoConfigurableProductCartItemInput | null>) => {
  selectedItems.value = {
    ...selectedItems.value,
    ...item,
  }
}

const selectedSkus = computed<string[]>(() =>
  Object.values(selectedItems.value).map((item: MagentoCartItemInput | null) => item ? item.data.sku : null).filter((item: string | null) => item),
)

const deliveryPromises = computed(() =>
  selectedSkus.value.map((sku: string) => deliveryPromiseForSku(sku)),
)

const isOos = computed(() => {
  if (props.product?.__typename === 'SimpleProduct') {
    return props.product.stock_status === 'OUT_OF_STOCK'
  }
  if (props.product?.__typename === 'ConfigurableProduct') {
    if (props.product.variants.every(item => item.product?.stock_status === 'OUT_OF_STOCK')) {
      return true
    }
    const selectedItemsArray = Object.values(selectedItems.value)
    if (selectedItemsArray.filter(item => item).length < selectedItemsArray.length) {
      return true
    }
  }
  return false
})

const add = () => {
  const item = Object.values(selectedItems.value).filter(item => item)
  if (!item.length) {
    hasError.value = true
  }
  else {
    addToCart(item, false)
    hasError.value = false
  }
}
</script>

<style src="~layers/app/components/Product/AddToCart.css" />
